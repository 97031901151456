import slugify from "@sindresorhus/slugify";

import dirs from "@/contentful/content-model/dirs";
import { Dirs } from "@/contentful/content-model/types";

// Returns e.g. "my-job-title-13243546" for use as a URL route
export const getJobSlug = (id: number | string, title: string) => `${slugify(title)}-${id}`;

// We link to our own page which integrates a Greeehouse script to load the job
export const getJobUrl = ({ locale, id, title }: { locale: string; id: number; title: string }) => {
	return `/${locale}/${dirs[Dirs.careers].dir[locale]}/job/${getJobSlug(id, title)}/`;
};
