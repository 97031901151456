import React from "react";

import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useDebouncedCallback } from "use-debounce";

import type { RowProps } from "@/components/grid";
import { GRID_PADDING, GUTTER, Row } from "@/components/grid";
import type { PropsWithTheme } from "@/theme";

// @todo This is a newer version of the existing hook `@/hooks/viewport` (potential replacement)
export const useViewport = () => {
	const theme = useTheme();

	const [l, setL] = React.useState(false);
	const [m, setM] = React.useState(false);
	const [s, setS] = React.useState(true);
	const handleViewport = React.useCallback(() => {
		if (typeof window !== "undefined" && Boolean(theme?.breakpoints)) {
			setL(window.innerWidth >= theme.breakpoints.l);
			setM(window.innerWidth >= theme.breakpoints.m);
			setS(window.innerWidth >= theme.breakpoints.s);
		}
	}, [theme]);

	const debouncedHandleViewport = useDebouncedCallback(handleViewport, 300);

	React.useEffect(() => {
		handleViewport();
		window.addEventListener("resize", debouncedHandleViewport);
		return () => {
			window.removeEventListener("resize", debouncedHandleViewport);
		};
	}, [handleViewport, debouncedHandleViewport]);

	return { s, m, l };
};

// @todo This is a modified version of the existing ScrollableRow `@/components/boxes` (potential replacement)
export const ScrollableRow = styled(Row)<PropsWithTheme & RowProps>`
	flex-wrap: nowrap;
	padding-bottom: 20px;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	scroll-snap-type: x mandatory;
	scroll-padding: 0 calc(var(${GUTTER}) * 1px + var(${GRID_PADDING}) * 1px);
	clip-path: polygon(0 0, 0 calc(100% - 20px), 100% calc(100% - 20px), 100% 0);
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			padding-bottom: 0;
			clip-path: none;
			scroll-snap-type: unset;
			flex-wrap: wrap;
			overflow-x: visible;
			-webkit-overflow-scrolling: unset;
		}
	`};
`;
