import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { DialogProps } from "@material-ui/core/Dialog";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "next-i18next";

import { Box } from "@/components/grid";
import { ButtonRaw } from "@/design-system/atoms/button";
import type { PropsWithTheme } from "@/theme";
import type { FullScreenDialogProps } from "@/types/dialog";

export const StyledDialog = styled(Dialog)<PropsWithTheme>`
	--grid-gutter: 8;
	--grid-grid-padding: 24;
	--grid-padding: 0;
	--grid-colspan: 8;

	.MuiDialog-container {
		padding-top: var(--spacing-l);
		background-color: black;
	}

	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			.MuiDialog-container {
				padding-top: 0;
			}
		}
	`};
`;

export const StyledButton = styled(ButtonRaw)<PropsWithTheme<HTMLButtonElement>>`
	position: fixed;
	z-index: 1;
	top: var(--spacing-s);
	right: var(--spacing-s);
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[0]};
	`};
`;

export const FullScreenDialog: React.FC<FullScreenDialogProps & DialogProps> = ({
	ariaLabeledById,
	children,
	open,
	onClose,
	...props
}) => {
	const { t } = useTranslation("common");
	return (
		<StyledDialog
			maxWidth="md"
			open={open}
			aria-labelledby={ariaLabeledById}
			onClose={onClose}
			{...props}
		>
			<div style={{ overflowX: "hidden" }}>
				<Box removeGutter removePadding>
					{children}
				</Box>
			</div>
			<StyledButton aria-label={t("common:close")} onClick={onClose}>
				<CloseIcon aria-hidden="true" />
			</StyledButton>
		</StyledDialog>
	);
};
