import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { animated } from "@react-spring/web";

import { Column, Grid, Row } from "@/components/grid";
import { StyledArrowRightIcon, StyledButtonIcon } from "@/design-system/atoms/button";
import { useSnackbarTransitions } from "@/hooks/snackbar";
import type { PropsWithTheme } from "@/theme";

export const StyledButton = styled(StyledButtonIcon)`
	width: 100%;
	height: 45px;
`;

export const StyledSnackbar = styled(animated.div)<PropsWithTheme>`
	position: fixed;
	z-index: 20;
	right: 0;
	bottom: 0;
	left: 0;
	padding: var(--spacing-xs) 0;
	box-shadow: 0 -30px 27px 0 rgba(0, 0, 0, 0.09);
	${({ theme: { palette } }) => css`
		background: ${palette.freeze[0]};
	`};
`;

export interface SnackbarProps {
	arrowIcon?: boolean;
	buttonLabel: string;
	handleClick: () => void;
	showSnackbar?: boolean;
}

export const Snackbar: React.FC<SnackbarProps> = ({
	arrowIcon,
	buttonLabel,
	children,
	handleClick,
	showSnackbar,
	...rest
}) => {
	const transitions = useSnackbarTransitions(showSnackbar);
	return (
		<>
			{transitions(
				(style, item) =>
					item && (
						<StyledSnackbar style={style} {...rest}>
							<Grid overflow>
								<Row>
									<Column flex s={0} l={9}>
										{children}
									</Column>
									<Column flex l={3}>
										<StyledButton onClick={handleClick}>
											{buttonLabel}
											{arrowIcon && <StyledArrowRightIcon />}
										</StyledButton>
									</Column>
								</Row>
							</Grid>
						</StyledSnackbar>
					)
			)}
		</>
	);
};
