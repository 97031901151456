import React from "react";

interface FormattedPriceOptions {
	locale: string;
	shorten?: boolean;
	style?: "decimal" | "currency";
	value: number;
}

export const useFormattedPrice = ({
	locale,
	shorten = false,
	style = "currency",
	value,
}: FormattedPriceOptions): string => {
	return React.useMemo(() => {
		if (!value) {
			// Some values can be deliberately set to "null" if we don't want to show
			// them e.g. pricePerM2 is null on /listing but not on /web-expose
			return;
		}
		const options: Intl.NumberFormatOptions = {
			minimumFractionDigits: 0,
			style,
			...(style === "currency" ? { currency: "EUR" } : {}),
			...(shorten ? { compactDisplay: "short", notation: "compact" } : {}),
		};
		const formattedValue = new Intl.NumberFormat(locale, options).format(value);

		if (shorten && style === "decimal") {
			return `${formattedValue} €`;
		}

		return formattedValue;
	}, [locale, shorten, style, value]);
};
