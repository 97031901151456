import React from "react";

import { useTransition } from "@react-spring/web";

export const useSnackbarTransitions = (isVisible: boolean) =>
	useTransition<boolean, object>(isVisible, {
		from: { transform: "translate3d(0,100%,0)" },
		enter: { transform: "translate3d(0,0,0)" },
		leave: { transform: "translate3d(0,100%,0)" },
	});

// Initial usage: used to show the snackbar when the user scrolls past the top element and the contact form
export const useSnackbarVisibility = (): [
	React.RefObject<HTMLDivElement>,
	React.RefObject<HTMLDivElement>,
	boolean
] => {
	const [isTopElementVisible, setIsTopElementVisible] = React.useState(true);
	const [isFormVisible, setIsFormVisible] = React.useState(true);
	const [showSnackbar, setShowSnackbar] = React.useState(false);

	const ref1 = React.useRef<HTMLDivElement>(null);
	const ref2 = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		// Ensures correct expectations in useEffect clean-up
		const localRef1 = ref1.current;
		const localRef2 = ref2.current;
		const options = { threshold: 0.1 };

		const topObserver = new IntersectionObserver(([entry]) => {
			setIsTopElementVisible(entry.isIntersecting);
		}, options);

		const formObserver = new IntersectionObserver(([entry]) => {
			setIsFormVisible(entry.isIntersecting);
		}, options);

		if (localRef1) {
			topObserver.observe(localRef1);
		}

		if (localRef2) {
			formObserver.observe(localRef2);
		}

		return () => {
			if (localRef1) {
				topObserver.unobserve(localRef1);
			}

			if (localRef2) {
				formObserver.unobserve(localRef2);
			}
		};
	}, []);

	React.useEffect(() => {
		setShowSnackbar(!isTopElementVisible && !isFormVisible);
	}, [isTopElementVisible, isFormVisible]);

	return [ref1, ref2, showSnackbar];
};
