import React from "react";

export interface ScrollConfig {
	behavior?: ScrollBehavior;
	offset?: number;
}

export const useScrollTo = (id: string, { behavior = "smooth", offset = 0 }: ScrollConfig = {}) =>
	React.useCallback(() => {
		const element = document.querySelector(`#${id}`);

		if (element) {
			const top = element.getBoundingClientRect().top + window.scrollY;
			window.scrollTo({
				behavior,
				left: 0,
				top: top - offset,
			});
		}
	}, [offset, behavior, id]);

export const useScrollToWithHeader = (id: string, options: ScrollToOptions = {}) => {
	const [offset, setOffset] = React.useState(0);

	React.useEffect(() => {
		const element = document.querySelector("#__next > div");
		const headerHeight = window.getComputedStyle(element).getPropertyValue("--header-height");
		setOffset(headerHeight ? Number.parseInt(headerHeight, 10) : 0);
	}, []);

	return useScrollTo(id, { ...options, offset });
};
